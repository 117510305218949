<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-10 pt-10" style="margin-top: 30px">
            <a-col :span="24" :md="18" :lg="18" :xl="18" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option.uuid)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
            </a-col>

            <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24 text-right">
                <router-link to="/my-classes/publish-new-notes">
                    <a-button
                        id="btnBordered"
                        type="text"
                        class="ml-15 text-primary">
                        <a-icon type="plus" theme="outlined" />
                        Publish New Notes
                    </a-button>
                </router-link>
            </a-col>
        </a-row>


        <!-- <a-row :gutter="24" class="mb-0 pb-0 px-20">

                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                    <a-button
                        id="textBtn"
                        type="text"
                        @click="launchFilter"
                        class="">
                        <span style="font-size: 14px; text-decoration: underline;">Filter Classes
                            <a-icon type="filter" theme="outlined" />
                        </span>
                    </a-button>
                </a-col>

            </a-row> -->

		<!-- <a-row :gutter="24" class="px-20">
			<a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 text-right">
				<a-pagination type="success" class="btn btn-silabu" v-model="currentPage" :total="pagination.total" :pageSize="pagination.perPage" show-less-items />
			</a-col>
		</a-row> -->

		<div v-if="!loadingData" class="mb-20">

            <a-row :gutter="24" class="px-20"  v-if="pagination.filter == 'my-notes'">
				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">

                    <a-card class="px-20">
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5 px-10">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <h6 style="font-size: 18px;">{{ notes.length }} Notes</h6>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ noteEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">

                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
                                    :data-source="notes"
                                    :loading="loadingData" 
                                    :pagination="true" 
                                    :rowKey="record => record.uuid"
                                    width="100%" 
                                    style="width: 100%;">

                                        <template slot="quizTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                            {{ formatName(row.title.length > 35 ? `${row.title.substring(0, 35)}...` : row.title ) }}
                                        </template>

                                        <template slot="instructor" slot-scope="row"> 
                                            <a-avatar :src="row.creator.profile" class="mr-5"/>
                                            <!-- {{ `${row.tutor.firstName} ${row.tutor.lastName}` }} -->
                                            {{ `You` }}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.completed.length}}
                                        </template>

                                        <template slot="revenue" slot-scope="row"> 
                                            {{ row.isFree ? '0' : (row.price * (row.members.length - 1)).toLocaleString() }}/=
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                @click="onViewFile(row)"
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                style="border-radius: 20px;">
                                                
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                    </a-card>
				</a-col>
            </a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;

    const columns = [
        {
            title: 'Quiz title',
            scopedSlots: { customRender: 'quizTitle' },
            width: 200
        },
        // {
        //     title: 'Owner',
        //     scopedSlots: { customRender: 'instructor' },
        //     width: 200
        // },
        {
            title: '# of Students',
            scopedSlots: { customRender: 'students' },
            key: 'sudents',
            width: 100
        },
        {
            title: 'Revenue',
            scopedSlots: { customRender: 'revenue' },
            width: 100
        },
        {
            title: 'View',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                columns,

				notes: [],
                earningNotes: [],
                noteEarnings: 0,
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'my-notes',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filterItems: [
                    { uuid: 'my-notes', name: 'My Notes ', icon: ' 👤'},
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getNotes();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					this.pagination.currentPage = this.currentPage;
					this.getNotes()
                },
            },
            deep: true,
        },
		mounted () {
			let breadcrumbs = [
				{ title: 'Notes', url: '/notes', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.getNotes()
		},
		methods: {

            async onViewFile(item) {

                await localStorage.setItem("notesUuid", item.uuid);
				
                if((item.content.path != null && item.content.path.includes('.pdf')) || (item.thumbnail != null && item.thumbnail.includes('.pdf'))) {
                    await localStorage.setItem("pdfFilePath", item.content.path)
                    await localStorage.setItem("pdfFileName", item.thumbnail)

                    window.open(`/pdf-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.doc')) || (item.thumbnail != null && item.thumbnail.includes('.doc'))) {
                    await localStorage.setItem("docFilePath", item.content.path)
                    await localStorage.setItem("docFileName", item.thumbnail)

                    window.open(`/doc-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.mkv')) || (item.content.path != null && item.content.path.includes('.mp4'))){
                    await localStorage.setItem("videoFilePath", item.content.path)
                    await localStorage.setItem("videoFileName", item.thumbnail)

                    window.open(`/video-player`, '_blank');
                }
                else{
                    console.log(item.content)

                    this.notify('File format currently is not supported', 'error')
                }
				// this.$router.push(`/pdf-viewer`);
			},

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },


            onSelectFilterItem(item) {
                this.pagination.filter = item;

                if(item == 'my-notes') {
                    this.getNotes();
                }
            },


			async getNotes() {

				this.loadingData = true;

                const userInfo = await localStorage.getItem('user_details')

                this.loadingData = true;

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

                const currentPage = 1;
                const perPage = 1000;
                const { search } = this.pagination;

                let url = '';

                if(search != null && search.length > 0) {
                    url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&populate=true&search=${search}&page=${currentPage}&size=${perPage}`;
                }else{
                    url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&populate=true&page=${currentPage}&size=${perPage}`;
                }


                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.notes = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
						this.currentPage = response.data.pagination != null ? response.data.pagination.currentPage : 1;
                        
                        this.noteEarnings(response.data.data);
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getEarnings(notes) {

				
                if(notes != null && notes.length > 0) {
                    notes.forEach(element => {

                        if(!element.isFree && element.price != null) {
                            this.notesEarnings += element.price;
                        }

                    });
                }
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>